import React from 'react';
import ReactDOM from 'react-dom';

import Root from '../../containers/Root'

import theme from '../../assets/bundles/aftonbladet/theme.json'
import config from '../../assets/bundles/aftonbladet/config.json'

import translations from '../../translations';
import { store } from '../index'

const transformedConfig = {
    ...config,
    auth : {
        ...config.auth,
        redirect : () => {

            

            const callbackUrl = window.location.href.indexOf('signin') < 0 ?
                `${ window.location.origin }/se/aftonbladet/callback?returnUrl=${ encodeURIComponent(window.location.href) }` :
                `${ window.location.origin }/se/aftonbladet/callback`;

            const state  = encodeURIComponent(JSON.stringify({ returnPath: callbackUrl }));
            const url = `https://login.schibsted.com/oauth/authorize?client_id=5e4e6b8dba2d9d68e7f1ccdc&redirect_uri=https%3A%2F%2Fwww.aftonbladet.se%2Fcallback&response_type=code&new-flow=true&scope=openid&state=${ state }&prompt=select_account`
            return url;
        }
    }
}

console.log('Mounting app...');
const rootEl = document.getElementById('sw-root');
if (rootEl) {
    ReactDOM.render(<Root store={ store } theme={ theme } config={ transformedConfig } translations={ translations }></Root>, rootEl)
}